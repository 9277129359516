import React, { useState, useEffect, useMemo } from "react";
import DeletePostPrompt from "../DeletePostPrompt";
import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Chip,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
  Fade,
  Box,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { getPosts, deleteDocument } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPost } from "../../applicationSlice";
import CloseIcon from "@mui/icons-material/Close";
import TagDisplay from "../TagDisplay";
import TagDialog from "../TagDialog"; // Import the tag popup component
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
const ManagePosts = () => {
  const [myPosts, setMyPosts] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);
  const [post, setPost] = useState(null);
  const [showPublished, setShowPublished] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [viewPostTags, setViewPostTags] = useState([]);

  const [sortOrder, setSortOrder] = useState("newest");
  const [tags, setTags] = useState([]);
  const [openTags, setOpenTags] = useState(false);
  const [openTagDialog, setOpenTagDialog] = useState(false);
   const [showFilters, setShowFilters] = useState(false); // Toggle for filter section
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);

  // Check screen size for responsive layout
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detects small screens

  useEffect(() => {
    getPosts(application.currentUser.uid)
      .then((p) => {
        setMyPosts(p);

        // Normalize, remove duplicates, and sort A-Z
        const uniqueTags = [
          ...new Set(
            p.flatMap((post) => (post.tags || []).map((tag) => tag.trim().toLowerCase()))
          ),
        ].sort((a, b) => a.localeCompare(b));

        setTags(uniqueTags);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleEditClick = (post) => {
    dispatch(setSelectedPost(post));
    navigate("/edit-post");
  };

  const handleDeleteClick = (post) => {
    setPost(post);
    setPromptOpen(true);
  };

  const handleConfirmDelete = () => {
    setPromptOpen(false);
    if (post) {
      deleteDocument(post.id, "posts")
        .then(() => {
          setMyPosts(myPosts.filter((p) => p.id !== post.id));
          setPost(null);
        })
        .catch((err) => console.error(err));
    }
  };
  const handleShowTags = (tags) => {
    setViewPostTags(tags || []);
    setOpenTagDialog(true);
  };
  const handleTagChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };
  const clearFilters = () => {
    setSelectedTags([]);
    setSearchQuery("");
    setSortOrder("newest");
  };
   // ✅ Include "Published/Drafts" toggle in filteredPosts
  const filteredPosts = useMemo(() => {
    let filtered = myPosts.filter((post) => {
      const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesTags = selectedTags.length === 0 || post.tags?.some((tag) => selectedTags.includes(tag));
      const matchesPublished = showPublished ? post.published === true : post.published === false || post.published === undefined;
      return matchesSearch && matchesTags && matchesPublished;
    });

    switch (sortOrder) {
      case "newest":
        return [...filtered].sort((a, b) => new Date(b.created) - new Date(a.created));
      case "oldest":
        return [...filtered].sort((a, b) => new Date(a.created) - new Date(b.created));
      case "a-z":
        return [...filtered].sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return [...filtered].sort((a, b) => b.title.localeCompare(a.title));
      default:
        return filtered;
    }
  }, [myPosts, searchQuery, selectedTags, sortOrder, showPublished]);

  return (
    <Container maxWidth={"lg"}>
      <DeletePostPrompt
        openPrompt={promptOpen}
        handleClose={(confirm) => {
          setPromptOpen(false);
          if (confirm) handleConfirmDelete();
        }}
      />
      <Typography variant="h6" align="center" gutterBottom>
        Manage Blog Posts
      </Typography>
    {/* Toggle Button to Show Search & Filters */}
    <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton onClick={() => setShowFilters(!showFilters)} color="primary">
          {showFilters ?<Typography variant="body2">Close Search Criteria <CloseIcon /></Typography> :<Typography variant="body2"> Open Search Criteria <SearchIcon /></Typography>}
        </IconButton>
      </Box>
       {/* Filter/Search Section (Hidden by Default, Uses Smooth Fade-in) */}
       <Fade in={showFilters} timeout={300}>
        <Box sx={{ display: showFilters ? "block" : "none", padding: 2, border: "1px solid #ccc", borderRadius: 2, mb: 2 }}>
          <Grid container spacing={2}>
            {/* Search Bar */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField fullWidth label="Search by Title" variant="outlined" value={searchQuery} onChange={handleSearchChange} />
            </Grid>

            {/* Sorting Dropdown */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Sort By</InputLabel>
                <Select value={sortOrder} onChange={handleSortChange}>
                  <MenuItem value="newest">Newest First</MenuItem>
                  <MenuItem value="oldest">Oldest First</MenuItem>
                  <MenuItem value="a-z">Alphabetical (A-Z)</MenuItem>
                  <MenuItem value="z-a">Reverse Alphabetical (Z-A)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Tag Selection Dropdown */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Select Tags</InputLabel>
                <Select
                  multiple
                  value={selectedTags}
                  onChange={handleTagChange}
                  renderValue={(selected) => selected.join(", ")}
                  open={openTags}
                  onClose={() => setOpenTags(false)}
                  onOpen={() => setOpenTags(true)}
                >
                  
                  {tags.map((tag, index) => (
                    <MenuItem onClick={()=>setOpenTags(false)} key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
   {/* ✅ Show Published/Drafts Toggle */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={<Switch checked={showPublished} onChange={() => setShowPublished(!showPublished)} />}
                label={showPublished ? "Show Published" : "Show Drafts"}
              />
            </Grid>
            {/* "Clear All Filters" Button */}
            {(selectedTags.length > 0 || searchQuery || sortOrder !== "newest") && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button variant="outlined" color="secondary" onClick={clearFilters}>
                  Clear All Filters
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Fade>

      {/* Mobile View: Cards instead of Table */}
      {isMobile ? (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {filteredPosts.map((row, index) => (
            <Grid item xs={12} key={row.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{row.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Published: {row.published ? "Yes" : "No"}
                  </Typography>
                  <TagDisplay tags={row.tags || []} />
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleEditClick(row)} variant="contained" color="secondary">
                    Edit
                  </Button>
                  <Button onClick={() => handleDeleteClick(row)} variant="contained" color="error">
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Desktop View: Keep Table
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="blog table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Index</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="right">Published</TableCell>
                <TableCell align="center">Tags</TableCell>
                <TableCell align="right">Actions</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPosts.map((row, index) => (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell component="th" scope="row">{row.title}</TableCell>
                  <TableCell align="right">{row.published ? "Yes" : "No"}</TableCell>
                  <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={() => handleShowTags(row.tags)}
        >
          Show Tags
        </Button>
      </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleEditClick(row)} variant="contained" color="secondary">
                      Edit
                    </Button>
                    <Button onClick={() => handleDeleteClick(row)} variant="contained" color="error">
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

<TagDialog open={openTagDialog} onClose={() => setOpenTagDialog(false)} tags={viewPostTags} />
    </Container>
  );
};

export default ManagePosts;
