import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

const PropertyMetadata = ({ formData, setFormData }) => {
  const handleAddTrait = (type) => {
    const lastId = formData[type][formData[type].length - 1]?.id || 0;
    setFormData({
      ...formData,
      [type]: [...formData[type], { id: lastId + 1, category: "", name: "" }],
    });
  };

  const handleRemoveTrait = (type, id) => {
    const newMetadata = formData[type].filter((item) => item.id !== id);
    setFormData({
      ...formData,
      [type]: newMetadata,
    });
  };

  const TraitMetadataInputField = (id, type) => (
    <Grid container spacing={2} key={id} sx={{padding:1}}>
      <Grid item xs={10} sm={5}>
        <TextField
          fullWidth
          label="Category"
          variant="outlined"
          value={formData[type].find((metadata) => metadata.id === id).category}
          onChange={(e) => handleTraitChange(e, type, id, "category")}
        />
      </Grid>
      <Grid item xs={10} sm={5}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={formData[type].find((metadata) => metadata.id === id).name}
          onChange={(e) => handleTraitChange(e, type, id, "name")}
        />
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="error" onClick={() => handleRemoveTrait(type, id)}>
          X
        </Button>
      </Grid>
    </Grid>
  );

  const handleTraitChange = (event, type, id, field) => {
    const newMetadata = formData[type].map((item) => {
      if (item.id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setFormData({
      ...formData,
      [type]: newMetadata,
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Property Metadata
      </Typography>
      {["external_url", "description"].map((key) => (
        <TextField
          key={key}
          fullWidth
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          variant="outlined"
          value={formData[key]}
          onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
          margin="normal"
        />
      ))}
      <Typography variant="h6" gutterBottom>
        Rarity Traits
      </Typography>
      {formData.traits.map((metadata) => TraitMetadataInputField(metadata.id, "traits"))}
      <Button onClick={() => handleAddTrait("traits")} variant="contained" color="primary">
        Add Trait
      </Button>
      <Typography variant="h6" gutterBottom>
        Non-Rarity Traits
      </Typography>
      {formData.filters.map((metadata) => TraitMetadataInputField(metadata.id, "filters"))}
      <Button onClick={() => handleAddTrait("filters")} variant="contained" color="primary">
        Add Filter
      </Button>
    </Box>
  );
};

export default PropertyMetadata;
