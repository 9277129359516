import React from "react";
import { Dialog, DialogTitle, DialogContent, Stack, Chip, Button } from "@mui/material";

const TagDialog = ({ open, onClose, tags }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tags</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap", padding: 1 }}>
          {tags.length > 0 ? (
            tags.map((tag, index) => <Chip key={index} label={tag} variant="outlined" size="small" />)
          ) : (
            <p>No tags available</p>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TagDialog;
