import {  Box, Grid, Paper, Typography } from "@mui/material";


const LandingPage=()=> {

return (
        <Grid container spacing={1} sx={{pt:2}}>
           <Grid item xs={12}>
                <Paper elevation={4}>
                <Box sx={{padding:2}}>
                <Typography variant="h4">Welcome to Heart's Ease Cottage!</Typography>
                 <Typography variant="body1">{process.env.REACT_APP_SITE_MOTTO}</Typography>
                </Box>
               </Paper>
               </Grid>
</Grid>

             
     
   )
}
export default LandingPage;