import { useState,useEffect } from "react";
import {PeraWalletConnect} from "@perawallet/connect"
import PropertyMetadata from '../PropertyMetadata';  
import ResponsiveImage from '../ResponsiveImage';
import Resizer from "react-image-file-resizer";
import { ASSET_PREVIEW, TOOLS } from "../../Utilities/constants";
import {
    Button,
    TextField,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    InputLabel,
    Box,
    Typography,
    Grid,
    Paper,
    Modal
  } from "@mui/material";
import algosdk from "algosdk";
import { toast } from "react-toastify";
import { useAtom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';
import {
  getNodeURL,
  getAssetPreviewURL,
  getTokenPreviewURL,
  createARC3AssetMintArrayV2,
  createARC19AssetMintArrayV2,
  createAssetMintArrayV2,
} from "../../Utilities/nftUtil";
import { pinImageToCrust } from "../../Utilities/crust";
import { signLoginAlgorandForCrustIpfsEndpoint } from "../../Utilities/crust-auth";
import WalletDisplay from '../WalletDisplay';
import ConnectButton from '../ConnectButton';
import UserAccountMenu from '../UserAccountMenu'
const peraWallet = new PeraWalletConnect();

const simpleMintAtom = atomWithStorage('simpleMint', {
  name: "",
  unitName: "",
  totalSupply: 1,
  decimals: 0,
  image: null,
  format: "ARC19",
  freeze: false,
  clawback: false,
  defaultFrozen: false,
  urlField: "",
  description: "",
  external_url: "",
  traits: [
    {
      id: 1,
      category: "",
      name: "",
    },
  ],
  filters: [
    {
      id: 1,
      category: "",
      name: "",
    },
  ],
  extras: [
    {
      id: 1,
      category: "",
      name: "",
    },
  ],
});

export function SimpleMint() {
  const [formData, setFormData] = useAtom(simpleMintAtom);
  const [processStep, setProcessStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [walletAddress,setWalletAddress] = useState('');
  const [createdAssetID, setCreatedAssetID] = useState(null);
  const [image,setImage] = useState('');
  const [file,setFile] = useState(null);

  const [imageCID,setImageCID] = useState('');
  const [imageIPFS,setImageIPFS] = useState('');
  const [crustAuth,setCrustAuth] = useState('');

  // batchATC is a AtomicTransactionComposer to batch and send all transactions
  const [batchATC, setBatchATC] = useState(null);
  useEffect(() => {
    // Reconnect to the session when the component is mounted
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        if(accounts===0)return;
        peraWallet.connector.on("disconnect", handleDisconnectWalletClick);

        if (accounts.length) {
          console.log(`reconnecting session: ${accounts[0]}`);
          setWalletAddress(accounts[0]);
            
          var ab = localStorage.getItem("authBasic");
          if(!ab){
            signLoginAlgorandForCrustIpfsEndpoint(accounts[0]).then((authBasic)=>{
              console.log(`reconnecting session: ${authBasic}`);
              localStorage.setItem("authBasic",authBasic);
              setCrustAuth(authBasic);
             }).catch((err)=>toast.error(err));
          }else{
            setCrustAuth(ab);

          }
          
        }
      })
      .catch((e) => console.log(e));
  }, []);
  async function mint() {
    try {
   
      if (!walletAddress) {
        console.log('Please connect your wallet:',walletAddress);
        toast.error("Please connect your wallet");
        return;
      }
      console.log('mint clicked. Setting Wallet:',walletAddress);
      localStorage.setItem("wallet",walletAddress);
  
      if (
        formData.name === "" ||
        formData.unitName === "" ||
        formData.totalSupply === "" ||
        formData.decimals === ""
      ) {
        toast.error("Please fill all the required fields");
        return;
      }
      if (formData.format !== "Token" && formData.image === null) {
        toast.error("Please select an image");
        return;
      }
      setProcessStep(1);
      let metadata = {
        name: formData.name,
        standard: formData.format.toLocaleLowerCase(),
        properties: {},
      };
      if (formData.external_url !== "") {
        metadata.external_url = formData.external_url;
      }
      if (formData.description !== "") {
        metadata.description = formData.description;
      }
      if (formData.traits.length > 0) {
        metadata.properties.traits = formData.traits.reduce((acc, trait) => {
          if (trait.category !== "" && trait.name !== "") {
            acc[trait.category] = trait.name;
          }
          return acc;
        }, {});
      }
      if (formData.filters.length > 0) {
        metadata.properties.filters = formData.filters.reduce((acc, filter) => {
          if (filter.category !== "" && filter.name !== "") {
            acc[filter.category] = filter.name;
          }
          return acc;
        }, {});
      }
      if (formData.extras.length > 0) {
        metadata.properties.extras = formData.extras.reduce((acc, extra) => {
          if (extra.category !== "" && extra.name !== "") {
            acc[extra.category] = extra.name;
          }
          return acc;
        }, {});
      }
    let imageURL = '';
      if (formData.format === "Token") {
        imageURL = formData.urlField;
      } else {
        if (formData.image === null) {
          toast.error("Please select an image");
          return;
        }
        if(!imageCID){
          toast.error("Pin Image First");
          return;
        }
        imageURL = "ipfs://" + imageCID;

       
      }
      const nodeURL = getNodeURL();

      if (formData.image) {
        if (formData.image.type && formData.image.type.includes("video")) {
          metadata.animation_url = imageURL;
          metadata.animation_url_mime_type = formData.image
            ? formData.image.type
            : "";
        } else {
          metadata.image = imageURL;
          metadata.image_mime_type = formData.image ? formData.image.type : "";
        }
      }

      let metadataForIPFS = {
        asset_name: formData.name,
        unit_name: formData.unitName,
        has_clawback: formData.clawback ? "Y" : "N",
        has_freeze: formData.freeze ? "Y" : "N",
        default_frozen: formData.defaultFrozen ? "Y" : "N",
        decimals: formData.decimals,
        total_supply: formData.totalSupply,
        ipfs_data: metadata,
      };
      if (formData.format === "ARC3") {
        const batchATC = await createARC3AssetMintArrayV2([metadataForIPFS], nodeURL, [imageCID]);
        setBatchATC(batchATC);
      } else if (formData.format === "ARC19") {
        console.info(metadataForIPFS);
        const batchATC = await createARC19AssetMintArrayV2([metadataForIPFS], nodeURL, [imageCID]);
        console.info('batchATC:',batchATC);
        setBatchATC(batchATC);
      } else if (formData.format === "ARC69" || formData.format === "Token") {
        metadata.properties = metadata.properties.traits;
        metadataForIPFS = {
          ...metadataForIPFS,
          asset_note: metadata,
          asset_url: imageURL,
        };
        if (formData.format === "ARC69") {
          const batchATC = await createAssetMintArrayV2([metadataForIPFS], nodeURL, [imageCID]);
          setBatchATC(batchATC);
        } else {
          const batchATC = await createAssetMintArrayV2([metadataForIPFS], nodeURL);
          setBatchATC(batchATC);
        }
      } else {
        toast.error("Invalid ARC format");
        return;
      }
      toast.info("Please sign the transaction");
      setProcessStep(2);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
      setProcessStep(0);
    }
  }

  async function sendTransaction() {
    try {
      const wallet = localStorage.getItem("wallet");
      if (!wallet) {
        toast.error("Please connect your wallet");
        return;
      }
      if (!batchATC) {
        toast.error("Please create the transaction first");
        return;
      }
      setProcessStep(3);
      const nodeURL = getNodeURL();
      const algodClient = new algosdk.Algodv2("", nodeURL, {
        "User-Agent": "evil-tools",
      });

      const txres = await batchATC.execute(algodClient, 4);

      if (!txres || !txres.txIDs || txres.txIDs.length === 0) {
        console.error("transaction submit error, batchATC.execute return : ", txres);
        toast.error("transaction submit error");
        return
      }

      const assetCreateTxID = txres.txIDs[0];
      const result = await algosdk.waitForConfirmation(algodClient, assetCreateTxID, 3);
      setCreatedAssetID(result["asset-index"]);
      removeStoredData(); // Remove stored data now that mint is complete
      toast.success("Asset created successfully!");
      setProcessStep(4);
    } catch (error) {
      console.log("Something went wrong: ", error);
      toast.error("Something went wrong!");
      setProcessStep(2);
    }
  }
  const handlePinToCrust=()=>{
    if(!file){
      toast.error('No Image Selected!');
    }
    const authBasic = localStorage.getItem("authBasic");
    console.log('authBasic',authBasic);
     pinImageToCrust(authBasic, file).then((cid)=>{
        setImageCID(cid);
        const ipfs = "ipfs://" + cid;
        setImageIPFS(ipfs);

     }).catch((err)=>toast.error(err));
  }

  function handleDisconnectWalletClick() {
    if(peraWallet.isConnected){
      peraWallet.disconnect();
    }
    setWalletAddress('');
    localStorage.removeItem("authBasic");
    localStorage.removeItem("walletAddress");
 }
  /** Remove the locally stored data */
  function removeStoredData() {
    setFormData(RESET);
    // setToken(RESET);
  }
  const handleConnect = async () => {
    // Logic to connect to Pera Wallet
    if(peraWallet.isConnected){
      await peraWallet.disconnect();
    }
         peraWallet.connect().then(async (accounts) => {
          console.log("connectWallet",accounts);
            localStorage.setItem("walletAddress",accounts[0]);
            setWalletAddress(accounts[0]);
            peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
            signLoginAlgorandForCrustIpfsEndpoint(accounts[0]).then((authBasic)=>{
              console.log(`reconnecting session: ${authBasic}`);
              localStorage.setItem("authBasic",authBasic);
              setCrustAuth(authBasic);
             }).catch((err)=>toast.error(err));

         }).catch((error) => {
             if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
              console.error("handleVerifyWallet error",error);
              toast.error(error);
            }
         });
    
  };



  const handleSwitchAccount = () => {
    // Logic to switch accounts (e.g., reconnect with Pera Wallet)
    console.log('Switching account...');
    handleDisconnectWalletClick();
    handleConnect();
    // Call Pera Wallet SDK method to switch accounts if applicable

  };
  function handleMint(){
    mint().then(()=>{}).catch((err)=>toast.error(err));
  }

  return (
    <Box sx={{ mx: "auto", mb: 4, textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "40rem", gap: 2, minHeight: "100vh" }}>
 
    <FormControl component="fieldset" variant="outlined">
      <FormLabel component="legend">Asset Information</FormLabel>
     
      <FormGroup>
        <Grid container spacing={2}>
        
        {walletAddress?
        <Grid item xs={12} sm={4}>
          <UserAccountMenu
          walletAddress={walletAddress}
          crustAuth={crustAuth}
          onDisconnect={handleDisconnectWalletClick}
          onSwitchAccount={handleSwitchAccount}
        />
        </Grid>:
        <Grid item xs={12} sm={4}>
           <ConnectButton onConnect={handleConnect} />
        </Grid>
        }
        {image && 
         <Grid item xs={12} >
             <ResponsiveImage src={image} caption={imageIPFS}/>
         </Grid>}
       
         {formData.format !== "Token" && (<>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" color="secondary" fullWidth component="label">
                Select Image
               <input type="file" hidden accept="image/*,video/*" onChange={(e) => {
                const file = e.target.files[0];
                setFormData({...formData, image:file});
                setFile(file);
                if(file) {
                    Resizer.imageFileResizer(
                        file,
                        1000,
                        1000,
                        'jpeg',
                        100,
                        0,
                        uri => {
                            setImage(uri);
                            },
                        'base64'
                    );
                }
                }} />
             </Button>
            </Grid>
        
          <Grid item xs={12} sm={4}>
          <Button color="secondary" disabled={!crustAuth} variant="contained" fullWidth onClick={handlePinToCrust}>Pin to Crust</Button>
          </Grid>
          </>
        ) } 
        {formData.format === "Token" &&(
            <Grid item xs={12}>
          <TextField label="URL Field" variant="outlined" required value={formData.urlField} onChange={(e) => setFormData({...formData, urlField: e.target.value})} />
            </Grid>
        )}
        <Grid item xs={12}>
               <TextField fullWidth label="Asset Name" variant="outlined" required value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})} />
        </Grid>
        <Grid item xs={12}>
               <TextField fullWidth label="Unit Name" variant="outlined" required value={formData.unitName} onChange={(e) => setFormData({...formData, unitName: e.target.value})} />
        </Grid>
        <Grid item xs={12}>
              <TextField fullWidth label="Total Supply" variant="outlined" required type="number" value={formData.totalSupply} onChange={(e) => setFormData({...formData, totalSupply: e.target.value})} />
        </Grid>
        <Grid item xs={12}>
              <TextField fullWidth label="Decimals" variant="outlined" required type="number" value={formData.decimals} onChange={(e) => setFormData({...formData, decimals: e.target.value})} />
        </Grid>
        <Grid item xs={12}>
       <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Asset Format</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.format}
            label="Asset Format"
            onChange={(e) => setFormData({...formData, format: e.target.value})}
          >
            <MenuItem value="ARC3">ARC3 - Unchangeable</MenuItem>
            <MenuItem value="ARC19">ARC19 - Changeable Images and Data</MenuItem>
            <MenuItem value="ARC69">ARC69 - Changeable Data</MenuItem>
            <MenuItem value="Token">Token</MenuItem>
          </Select>
        </FormControl>
   </Grid>
   <Grid item xs={12}>
        <FormControlLabel control={<Checkbox checked={formData.freeze} onChange={(e) => setFormData({...formData, freeze: e.target.checked})} />} label="Freeze" />
    </Grid>
    <Grid item xs={12}>
        <FormControlLabel control={<Checkbox checked={formData.clawback} onChange={(e) => setFormData({...formData, clawback: e.target.checked})} />} label="Clawback" />
    </Grid>
    <Grid item xs={12}>
        <FormControlLabel control={<Checkbox checked={formData.defaultFrozen} onChange={(e) => setFormData({...formData, defaultFrozen: e.target.checked})} />} label="Default Frozen" />
    </Grid>
    <Grid item xs={12}>
        <PropertyMetadata formData={formData} setFormData={setFormData} />
    </Grid>
    <Grid item xs={12}>
    <Button onClick={handleMint} color="primary" variant="contained">Mint</Button>


    <div className="flex flex-col justify-center items-center w-[16rem]">
        {processStep === 4 ? (
          <>
            <p className="pt-4 text-green-500 text-sm">
              Asset created successfully!
              <br />
            </p>
            {createdAssetID && (
              <a
                href={ASSET_PREVIEW + createdAssetID}
                target="_blank"
                rel="noreferrer"
                className="text-primary-yellow hover:text-primary-yellow/80 transition text-lg py-2 animate-pulse"
              >
                View the Created Asset (May take up to 30s to Load)
              </a>
            )}
            <p className="pb-2 text-slate-400 text-xs">
              You can reload the page if you want to use again.
            </p>
          </>
        ) : processStep === 3 ? (
          <>
            <p className="pt-4 text-green-500 animate-pulse text-sm">
              Sending transaction...
            </p>
          </>
        ) : processStep === 2 ? (
          <>
            <p className="mt-1 text-green-200/60 text-sm animate-pulse">
              Transaction created!
            </p>
            <button
              id="create_transactions_id"
              className="rounded bg-secondary-orange hover:bg-secondary-orange/80 transition text-black/90 font-semibold px-4 py-1 mt-2"
              onClick={() => {
                sendTransaction();
              }}
            >
              Step 2: Sign
            </button>
          </>
        ) : processStep === 1 ? (
          <div className="mx-auto flex flex-col">
            <div
              className="spinner-border animate-spin inline-block mx-auto w-8 h-8 border-4 rounded-full"
              role="status"
            ></div>
            Creating transaction...
          </div>
        ) : (
          <button
            className="rounded bg-secondary-orange hover:bg-secondary-orange/80 transition text-black/90 font-semibold px-4 py-1 mt-2"
            onClick={mint}
          >
            Step 1: Mint
          </button>
        )}
      </div>
      <p className="text-sm italic text-slate-200 mb-6">
        Site Fee: Free/Pin Fee: Variable
      </p>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          removeStoredData();
          window.location.reload();
        }}
      >
        Clear &amp; start over
      </Button>
    </Grid>
</Grid>
      </FormGroup>
 
    </FormControl>
  </Box>
  );
}