import './App.css';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import LandingPage from './Components/LandingPage';
import { Route, Routes } from 'react-router-dom';
import Nav from './Components/Nav';
import Admin from './Components/Admin';
import SignUpForm from './Components/SignUpForm';
import { useEffect, useState } from 'react';
import { auth, getNFTs, getEditor } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setCollections, setCurrentUser } from './applicationSlice';
import HoldersWallet from './Components/HoldersWallet';
import ViewNFT from './Components/ViewNFT';
import { EditNFT } from './Components/EditNFT';
import SignIn from './Components/SignIn';
import AlgoAddict420 from './Components/AlgoAddict420';
import { About } from './Components/About';
import CreateNFT from './Components/CreateNFT';
import NFTCollectionViewer from './Components/NFTCollectionViewer';
import Blog from './Components/Blog';
import ManagePosts from './Components/ManageBlogPosts';
import VariableBlogEditor from './Components/VariableBlogEditor';
import BlogPost from './Components/BlogPost';
import {ARC19MintTool} from './Components/ARC19MintTool';
import { SimpleMint } from './Components/SimpleMint';
import { ToastContainer } from 'react-toastify';
const pastelColors = {
  pastelBlue: '#aec6cf',  // Example for primary
  pastelGreen: '#77dd77', // Example for secondary
  pastelYellow: '#fdfd96', // Example for background
  pastelRed: '#ff6961',   // Example for error
  pastelPurple: '#b19cd9' // Another option for secondary or contrast
};
const theme = createTheme({
  palette: {
    primary: {
      main: pastelColors.pastelBlue,
      contrastText: '#ffffff',
    },
    secondary: {
      main: pastelColors.pastelGreen,
      contrastText: '#ffffff',
    },
    error: {
      main: pastelColors.pastelRed,
    },
    background: {
      default: pastelColors.pastelYellow,
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    }
  },
  typography: {
    fontFamily: '"Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2.0rem',
    },
    body1: {
      fontSize: '1rem',
    },
  }
});
function App() {
  const application = useSelector((state)=>state.application);
  const wallet = useSelector((state)=>state.wallet);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  useEffect(()=>{
    setUser(application.currentUser??{});
    return ()=>{}
  },[application.currentUser])
  
    useEffect(() => {
     // getNFTs().then((nfts)=>dispatch(setCollections(nfts)));
     
      auth.onAuthStateChanged((auth)=> {
        console.log("Auth state changed",auth);
        if (auth) {
          getEditor(auth.uid).then((u=>{
            dispatch(setCurrentUser(u));
            setUser(u);
            
          }));
        }else{
          dispatch(setCurrentUser({}));
          setUser({});
          
        }
      });
    }, []);





  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Nav/>
     <Container fixed>
    <Routes>
   
    <Route path="/" exact element={<LandingPage/>} />
    <Route path="/about" exact element={<About/>} />
    <Route path="/blog" exact element={<Blog/>} />
    <Route path="/post/:blogId" element={<BlogPost />} /> {/* ✅ Accepts blogId */}
    <Route path="/gen-2"  element={<NFTCollectionViewer collectionId={'gen-2'}/>} />
    <Route path="/sign-up"  element={<SignUpForm/>} />
    <Route path="/sign-in"  element={<SignIn/>} />
    <Route path="/holders-wallet"  element={<HoldersWallet/>} />
    <Route path="/view-nft"  element={<ViewNFT/>} />
    {user.role==='admin'?<Route path="/admin"  element={<Admin/>} />:''}
    {user.role==='admin'?<Route path="/edit-nft"  element={<EditNFT/>} />:''}
    {user.role==='admin'?<Route path="/create-nft"  element={<CreateNFT/>} />:''}
    {user.role==='admin'?<Route path="/manage-posts"  element={<ManagePosts/>} />:''}
    {user.role==='admin'?<Route path="/edit-post"  element={<VariableBlogEditor/>} />:''}
    {user.role==='admin'?<Route path="/mint-arc19-nft"  element={<SimpleMint/>} />:''}








    </Routes>
    <AlgoAddict420/>
    <ToastContainer />
    </Container>
    </ThemeProvider>
  );
}

export default App;
