import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const WalletDisplay = ({ walletAddress, crustAuth }) => {
  // Function to format the address
  const formatAddress = (address) => {
    if (!address) return '';
    const firstPart = address.slice(0, 5);
    const lastPart = address.slice(-5);
    return `${firstPart}...${lastPart}`;
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {formatAddress(walletAddress)}
      </Typography>
      {crustAuth && (
        <Chip
          label="Crust Authenticated"
          color="primary"
          icon={<VerifiedUserIcon />}
          size="small"
          variant="outlined"
        />
      )}
    </Box>
  );
};

export default WalletDisplay;
