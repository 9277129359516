import React, { useState } from 'react';
import { Box, Grid, Typography, Modal, IconButton,Link as MuiLink } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ResponsiveImage from '../ResponsiveImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const TextImageDisplay = ({ part }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const text = part.content ?? '';
    const side = part.imageSide ?? 'left';
    const imageUrl = part.imageUrl;
    const imageCaption = part.caption; // Assuming there's a caption property
  
    return (
        <Grid container spacing={2}>
            {side === "left" ? (
                <>
                    <Grid item xs={12} sm={(part.type === 'image'?12:6)}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <ResponsiveImage src={imageUrl} alt="post-image"  handleOnClick={handleOpen} caption={imageCaption}/>
                        </Box>
                    </Grid>
                    {part.type==='textImage'&&
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{text}</Typography>
                    </Grid>
}
                </>
            ) : (
                <>
                  {part.type==='textImage'&&
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{text}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12} sm={(part.type === 'image'?12:6)}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <ResponsiveImage src={imageUrl} alt="" handleOnClick={handleOpen} />
                            <Typography variant="caption">{imageCaption}</Typography>
                        </Box>
                    </Grid>
                </>
            )}
          
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={imageUrl} alt={`Full size ${imageCaption}`} style={{ maxWidth: '100%', height: 'auto', borderRadius:2 }} />
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 2 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Modal>
        </Grid>
    );
};

export default TextImageDisplay;
