import React, { useState } from "react";
import { Chip, Stack, Tooltip, IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const TagDisplay = ({ tags }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Scrollable Tags (Horizontal Scrolling) */}
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: "auto", // Horizontal scroll if too many tags
          flexWrap: "nowrap",
          paddingBottom: 1,
          maxWidth: "100%",
        }}
      >
        {tags.slice(0, 3).map((tag, index) => (
          <Chip key={index} label={tag} variant="outlined" size="small" />
        ))}

        {tags.length > 3 && (
          <Tooltip title="Show More Tags">
            <IconButton size="small" onClick={() => setOpen(true)}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {/* Popup Dialog to Show All Tags */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>All Tags</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} variant="outlined" size="small" />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TagDisplay