import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Paper,Link as MuiLink, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import ImageCarousel from '../Carousel';
import DOMPurify from 'dompurify';
import TextImageDisplay from '../TextImageDisplay';
import ImageDisplay from '../ImageDisplay';
import { useNavigate, useParams } from "react-router-dom"; 
import { getBlogPostById } from '../../firebase';
import CommentList from '../CommentList';
import CommentForm from '../CommentForm';
const BlogPost = () => {
  const { blogId } = useParams(); // ✅ Get blogId from URL
  const navigate = useNavigate(); // ✅ For navigation
  const [post, setPost] = useState({type:'',title:'',parts:[],tags:[]});
  const [comments, setComments] = useState([]); // Assume some initial state or fetch from API
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const application = useSelector(state=>state.application);

  useEffect(() => {
    if (blogId) {
      setLoading(true);
      getBlogPostById(blogId)
        .then((data) => {
          if (data) {
            setPost(data);
            setError(false);
          } else {
            setError(true); // ✅ If post is not found, show error message
          }
        })
        .catch((error) => {
          console.error("Error fetching post:", error);
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [blogId]);
  const handleCommentSubmit = (commentText) => {
    const newComment = {
      user: 'Anonymous', // Replace with actual user data if available
      text: commentText,
    };
    setComments([...comments, newComment]);
    // Optionally send this to the backend
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error || !post || post.published === false) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h5" color="error" gutterBottom>
          Blog Post Not Found
        </Typography>
        <Typography variant="body1">
          The blog post you are looking for does not exist or has been removed.
        </Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate("/")}>
          Back to Blog
        </Button>
      </Container>
    );
  }
  return (
    <Container>
      <Typography variant="h3" gutterBottom>{post.title}</Typography>
      <Typography variant="body2" gutterBottom>{post.created}</Typography>
      <Paper elevation={4} sx={{p:2}}>
      {post.html && 
          <Box sx={{margin:4,padding:2}}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.html) }} />
        </Box>}
     { post.parts &&
      <>
        {post.parts.map((part, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            {(part.type??'paragraph') === 'paragraph' && (
              <Typography variant="body1">{part.content}</Typography>
            )}
            {(part.type??'paragraph') === 'image'  && (<ImageDisplay part={part}/>
            )}
            {(part.type??'paragraph') === 'textImage'  && (<TextImageDisplay part={part}/>
            )}
            {(part.type??'paragraph') === 'carousel' && (
              <ImageCarousel images={part.images} />
            )}
            {part.type === 'bulletList' && (
  <ul>
    {part.items.map((item, idx) => (
      <li key={idx}>{item}</li>
    ))}
  </ul>
)}
              {part.links && part.links.map((link, linkIndex) => (
                <MuiLink
                  key={linkIndex}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ display: 'block', mt: 1 }}
                >
                  {link.description || link.url}
                </MuiLink>
              ))}
          </Box>
        ))}
        </>
      }
      </Paper>
     
      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Comments</Typography>
      <CommentList comments={comments} />
      <CommentForm onCommentSubmit={handleCommentSubmit} />
    </Container>
  );
};

export default BlogPost;
