import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Container, Paper, Grid,Switch,IconButton,Accordion,AccordionDetails,AccordionSummary,List,ListItem,ListItemSecondaryAction} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import ImageUploader from '../ImageUploader';
import EditCarousel from '../EditCarousel';
import TagInput from '../TagInput';
import { updateDocument } from '../../firebase'; // Make sure this path is correct
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DndContext, MouseSensor, TouchSensor, KeyboardSensor,PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import SortableItem from '../SortableItem';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { BulletListDisplay, BulletListEditor } from '../BulletListEditor';

const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',  // Default to column layout for mobile
  marginBottom: '20px',
  '@media (min-width:600px)': {
    flexDirection: 'row', // Switch to row layout for larger screens
    alignItems: 'center',
  }
});


const VariableBlogEditor = () => {
  const navigate = useNavigate();
  const application = useSelector(state=>state.application);
  const [postId, setPostId] = useState('');
  const [parts, setParts] = useState([]);
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState('');
  const [html, setHTML] = useState('');
  const [user, setUser] = useState('');
  const [published,setPublished] = useState(false);
useEffect(() => {
    if (application.selectedPost) {
      const p = application.selectedPost;
      console.log(p);
      setPostId(p.id || '');
      setTitle(p.title || '');
      if(p.parts){
        var  l = p.parts.map(part => ({ ...part, id: uuidv4(),links: part.links || [] })) || [];
        setParts(l);
      console.log('parts new',l);

      }

      setTags(p.tags || []);    // Ensure tags is an array
      setPublished(p.published || false);
      setHTML(p.html??'');
    }
  }, [application.selectedPost]);
  useEffect(()=>{
    if(application.currentUser){
        setUser(application.currentUser);
    }
  },[application.currentUser]);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const addPart = (type) => {
    console.log(type);  
    if(type==='paragraph'){
      const newPart = {id: uuidv4(), type, content: '', links: [] }; // simplified initialization
      console.log('paragraph',newPart);
      setParts([...parts, newPart]);
    }
    if(type==='image'){
      const newPart = {id: uuidv4(), type, imageUrl: '', caption:'', links: []  }; // simplified initialization
      console.log('image',newPart);
      setParts([...parts, newPart]);
    }
    if(type==='textImage'){
      const newPart = {id: uuidv4(), type, content: '', imageUrl:'',caption:'',links: [], imageSide:'right' }; // simplified initialization
      console.log('textImage',newPart);
      setParts([...parts, newPart]);
    }
    if(type==='carousel'){
      const newPart = {id: uuidv4(), type, content: '', images: [],  links: [] }; // simplified initialization
      console.log('carousel',newPart);
      setParts([...parts, newPart]);
    }
    if (type === 'bulletList') {
      const newPart = { id: uuidv4(), type, items: [] }; // Initialize with an empty list
      setParts([...parts, newPart]);
    }

  };

  const handleContentChange = (index, field, value) => {
    const updatedParts = parts.map((part, i) => (i === index ? { ...part, [field]: value } : part));
    setParts(updatedParts);
  };


  const handleSave = async () => {
    var uid = user.uid;
    const uniqueId = `${uid}_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
    const xId = postId.length>0?postId:uniqueId
    console.log('postId',xId)
    const newPost = {
      id:xId,
      title,
      html:html??'',
      parts,
      tags,
      author:uid,
      created: new Date().toISOString(),
      published:published
    };
    

    try {
      updateDocument('posts',xId,newPost,true).then(()=>{
        alert('Post saved successfully!');
        setTitle('');
        setParts([]);
        setTags([]);
        navigate('/manage-posts');
      }).catch((err)=>toast.error(err));
    
    } catch (error) {
      console.error('Error saving the post:', error);
      alert('Failed to save the post.');
    }
  };
  const handleHtmlChange = (event) => {
    setHTML(event.target.value);
};
const removePart = (index) => {
  try {
    const newParts = parts.filter((_, i) => i !== index);
    setParts(newParts)
  } catch (err) {
    alert(err)
  }
;
};


const handleDragEnd = (event) => {
  try {
    if (!event || !event.active || !event.active.id) {
      console.error("Drag event is invalid:", event);
      return; // Exit if the drag event or active item is undefined or null
  }
    const { active, over } = event;
  
    if (active.id !== over.id) {
      const oldIndex = parts.findIndex(part => part.id === active.id);
      const newIndex = parts.findIndex(part => part.id === over.id);
      setParts(arrayMove(parts, oldIndex, newIndex));
    }
  } catch (err) {
    console.error(err)
  }

};


const handleLinkChange = (index, linkIndex, newLink) => {
  const updatedParts = parts.map((part, idx) => {
    if (idx === index) {
      const updatedLinks = [...part.links];
      updatedLinks[linkIndex] = newLink;
      return { ...part, links: updatedLinks };
    }
    return part;
  });
  setParts(updatedParts);
};

const addLink = (index) => {
  const newLink = { url: '', description: '' };
  const updatedParts = parts.map((part, idx) => {
      if (idx === index) {
          // Ensure there's a links array, initialize if not present
          const updatedLinks = Array.isArray(part.links) ? [...part.links, newLink] : [newLink];
          return { ...part, links: updatedLinks };
      }
      return part;
  });
  setParts(updatedParts);
};

const removeLink = (index, linkIndex) => {
  const updatedParts = parts.map((part, idx) => {
    if (idx === index) {
      const filteredLinks = part.links.filter((_, i) => i !== linkIndex);
      return { ...part, links: filteredLinks };
    }
    return part;
  });
  setParts(updatedParts);
};

const sensors = useSensors(
  useSensor(MouseSensor),
  useSensor(TouchSensor),
  useSensor(KeyboardSensor),
  useSensor(PointerSensor)
);

  return (
    <Container>
      <Paper sx={{ mt:2, p: 1 }} elevation={7}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="h5" gutterBottom>Post Editor</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="secondary" onClick={handleSave}>Save Post</Button>
          </Grid>

          <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label="Title"
          placeholder="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
          </Grid>
          <Grid item xs={12}>
              <Typography component="div">
      Published:
      <Switch
        checked={published}
        onChange={() => setPublished(!published)}
        name="published"
        color="primary"
      />
              </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
                  <Button fullWidth variant='contained' onClick={() => addPart('paragraph')} sx={{ mr: 1 }}>+ Paragraph</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Button fullWidth variant='contained' onClick={() => addPart('image')} sx={{ mr: 1 }}>+ Image</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Button fullWidth variant='contained' onClick={() => addPart('textImage')} sx={{ mr: 1 }}>+ Text/Image</Button>
          </Grid>
          <Grid item xs={12} sm={6}  md={3}>
          <Button fullWidth variant='contained' onClick={() => addPart('carousel')}>+ Image Carousel</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
  <Button fullWidth variant="contained" onClick={() => addPart('bulletList')}>
    + Bullet List
  </Button>
</Grid>
          {html &&html.length>0 && 
            <Grid item xs={12}>
               <Accordion  sx={{  backgroundColor: '#f7f9fc', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', border: '1px solid #e0e0e0' }}>
                    <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>HTML</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <TextField
                  fullWidth
        label={"HTML"}
        placeholder="HTML"
        multiline
        variant="outlined"
        value={html}
                  onChange={handleHtmlChange}
                  />
                  <Button variant='contained' onClick={()=>{setHTML('')}}>Remove</Button>
                 </AccordionDetails>
              </Accordion>
            </Grid>
          }

       <Grid item xs={12}>
         
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={parts.map(part => part.id)} strategy={verticalListSortingStrategy}>
              {parts.map((part, index) => (
                <SortableItem key={part.id??index} id={part.id??index}>
                  <ContentBox >
                  <Grid container spacing={1}>
        {(part.type === 'textImage' || part.type==='paragraph') && 
        ( <Grid item xs={10}  md={part.type === 'paragraph' ? 10 : 7}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            label={'Text'}
            value={part.content}
            onChange={(e) => handleContentChange(index, 'content', e.target.value)}
          />
        </Grid>)}
        {(part.type === 'textImage' || part.type==='image') && 
          <Grid item xs={10} md={(part.type === 'image'?10:3)}>
            <ImageUploader
              imageUrl={part.imageUrl}
              onImageUpload={(url) => handleContentChange(index, 'imageUrl', url)}
              imageSide={part.imageSide}
              imageType={part.type}
              onSideChange={(side) => handleContentChange(index, 'imageSide', side)}
              maxHeight={200}
              caption={part.caption??''}
              onCaptionChange={(caption)=> handleContentChange(index, 'caption', caption)}
            />
          </Grid>
        }
        {part.type === 'carousel' && (
          <Grid item xs={10} >
            <EditCarousel
              images={part.images}
              setImages={(newImages) => handleContentChange(index, 'images', newImages)}
            />
          </Grid>
        )}
        {part.type === 'bulletList' && (
  <Grid item xs={12}>
 
    <>
      <BulletListEditor
        value={part.items || []}
        onChange={(newItems) => handleContentChange(index, "items", newItems)}
      />
      <BulletListDisplay value={part.items || []} />
    </>
 
</Grid>
)}

        <Grid item xs={2}  >
          <IconButton
            onClick={() => removePart(index)}
            sx={{ margin: 'auto', display: 'block' }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
         <List>
              {part.links && part.links.map((link, linkIndex) => (
                <ListItem key={linkIndex}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                    <TextField
                    fullWidth
                    label="URL"
                    value={link.url}
                    onChange={(e) => handleLinkChange(index, linkIndex, { ...link, url: e.target.value })}
                    style={{ marginRight: '10px' }}
                  />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <TextField
                    fullWidth
                    label="Description"
                    value={link.description}
                    onChange={(e) => handleLinkChange(index, linkIndex, { ...link, description: e.target.value })}
                  />
                    </Grid>
                  </Grid>
                 
               
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => removeLink(index, linkIndex)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              <Button color="secondary" startIcon={<AddIcon />} onClick={() => addLink(index)}>
                Add Link
              </Button>
            </List>
            </Grid>
       </Grid>
    
       </ContentBox>
     </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
       </Grid>

       
          <Grid item xs={12}>
          <TagInput title="Tags" tags={tags} setTags={handleTagsChange} />
          </Grid>
       </Grid>
      
      
   
      </Paper>
    </Container>
  );
};

export default VariableBlogEditor;
