import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Collapse,
  Fade,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBlogPosts, setSelectedPost } from "../../applicationSlice";
import { getAllPosts } from "../../firebase";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const application = useSelector((state) => state.application);

  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [openTags, setOpenTags] = useState(false);
  const [showFilters, setShowFilters] = useState(false); // Toggle for filter section

  useEffect(() => {
    getAllPosts()
      .then((p) => {
        if (p.length > 0) {
          dispatch(setBlogPosts(p));

          // Normalize, deduplicate, and sort tags
          const uniqueTags = [
            ...new Set(
              p.flatMap((post) => (post.tags || []).map((tag) => tag.trim()))
            ),
          ].sort((a, b) => a.localeCompare(b));

          setTags(uniqueTags);
        }
      })
      .catch((err) => alert(err));
  }, []);

  useEffect(() => {
    if (application.blogPosts?.length > 0) {
      setPosts(application.blogPosts);
    }
  }, [application.blogPosts]);

  const handleReadMore = (post) => {
    navigate(`/post/${post.id}`);
  };

  const handleTagChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const filteredPosts = useMemo(() => {
    let filtered = posts.filter((post) => {
      const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase());
  
      
      // Change "every" to "some" for OR filtering
      const matchesTags =
        selectedTags.length === 0 || post.tags.some((tag) => selectedTags.includes(tag));
  
      return matchesSearch && matchesTags;
    });
  
    // Apply Sorting
    switch (sortOrder) {
      case "newest":
        return [...filtered].sort((a, b) => new Date(b.created) - new Date(a.created));
      case "oldest":
        return [...filtered].sort((a, b) => new Date(a.created) - new Date(b.created));
      case "a-z":
        return [...filtered].sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return [...filtered].sort((a, b) => b.title.localeCompare(a.title));
      default:
        return filtered;
    }
  }, [posts, searchQuery, selectedTags, sortOrder]);

  const clearFilters = () => {
    setSelectedTags([]);
    setSearchQuery("");
    setSortOrder("newest");
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Blog Posts
      </Typography>

      {/* Toggle Button to Show Search & Filters */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton onClick={() => setShowFilters(!showFilters)} color="primary">
          {showFilters ?<Typography variant="body2">Close Search Criteria <CloseIcon /></Typography> :<Typography variant="body2"> Open Search Criteria <SearchIcon /></Typography>}
        </IconButton>
      </Box>

      {/* Filter/Search Section (Hidden by Default, Uses Smooth Fade-in) */}
      <Fade in={showFilters} timeout={300}>
        <Box sx={{ display: showFilters ? "block" : "none", padding: 2, border: "1px solid #ccc", borderRadius: 2, mb: 2 }}>
          <Grid container spacing={2}>
            {/* Search Bar */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField fullWidth label="Search by Title" variant="outlined" value={searchQuery} onChange={handleSearchChange} />
            </Grid>

            {/* Sorting Dropdown */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Sort By</InputLabel>
                <Select value={sortOrder} onChange={handleSortChange}>
                  <MenuItem value="newest">Newest First</MenuItem>
                  <MenuItem value="oldest">Oldest First</MenuItem>
                  <MenuItem value="a-z">Alphabetical (A-Z)</MenuItem>
                  <MenuItem value="z-a">Reverse Alphabetical (Z-A)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Tag Selection Dropdown */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Select Tags</InputLabel>
                <Select
                  multiple
                  value={selectedTags}
                  onChange={handleTagChange}
                  renderValue={(selected) => selected.join(", ")}
                  open={openTags}
                  onClose={() => setOpenTags(false)}
                  onOpen={() => setOpenTags(true)}
                >
                  
                  {tags.map((tag, index) => (
                    <MenuItem onClick={()=>setOpenTags(false)} key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* "Clear All Filters" Button */}
            {(selectedTags.length > 0 || searchQuery || sortOrder !== "newest") && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button variant="outlined" color="secondary" onClick={clearFilters}>
                  Clear All Filters
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Fade>

      {/* Blog Posts Grid */}
      <Grid container spacing={4}>
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Card sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <CardContent>
                  {/* Blog Title */}
                  <Typography variant="h6" gutterBottom>
                    {post.title}
                  </Typography>

                  {/* Formatted Date */}
                  <Typography variant="body2" sx={{ color: "text.secondary", marginBottom: 1 }}>
                    {new Intl.DateTimeFormat("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(post.created))}
                  </Typography>

                  {/* Blog Tags */}
                  <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                    {post.tags.map((tag, index) => (
                      <Chip key={index} label={tag} variant="outlined" size="small" color="primary" />
                    ))}
                  </Stack>
                </CardContent>

                {/* Read More Button */}
                <CardActions sx={{ justifyContent: "flex-end", padding: 2 }}>
                  <Button variant="contained" onClick={() => handleReadMore(post)}>
                    Read More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center", width: "100%", marginTop: 3 }}>
            No posts found.
          </Typography>
        )}
      </Grid>
    </Container>
  );
};

export default Blog;
