import React from 'react';
import { Button } from '@mui/material';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet'; // Example icon for wallet connection

const ConnectButton = ({ onConnect }) => {
  return (
    <Button
    fullWidth
      variant="contained"
      onClick={onConnect}
      startIcon={<WalletIcon />}
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '16px',
        background: 'linear-gradient(135deg, #ff4081, #ff80ab)', // Stylish gradient
        '&:hover': {
          background: 'linear-gradient(135deg, #ff4081, #ff99bb)',
        },
      }}
    >
      Connect Wallet
    </Button>
  );
};

export default ConnectButton;
