import React from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';

const CommentList = ({ comments }) => (
  <List>
    {comments.map((comment, index) => (
      <React.Fragment key={index}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={comment.user}
            secondary={comment.text}
          />
        </ListItem>
        {index < comments.length - 1 && <Divider variant="inset" component="li" />}
      </React.Fragment>
    ))}
  </List>
);
export default CommentList