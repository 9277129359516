import { Button, Card, CardActionArea, CardActions, CardContent, Container, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LoadableImage } from "../LoadableImage";

export const About =()=>{
    const navigate = useNavigate();
    return(
        <Container maxWidth='md'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component={'h4'} variant="h4">About Heart's Ease Cottage</Typography>
                </Grid>
                <Grid item  xs={12} >
                 <Card >
                    <CardActionArea>
                    <CardContent>
                        <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                        Heart's Ease Cottage
                        </Typography>
                       </CardContent>
                      </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/blog')} size="small" color="primary">
          View Blog
        </Button>
      </CardActions>
                    </Card>

                </Grid>
               
            </Grid>
        </Container>
    )
}