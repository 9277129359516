import React from 'react';
import { Button, Menu, MenuItem, IconButton, Box, Chip,Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const UserAccountMenu = ({ onDisconnect, onSwitchAccount, walletAddress, crustAuth }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
        <Grid container>
            <Grid item xs={12}>
            <Button fullWidth
        variant="outlined"
        color="secondary"
        startIcon={<AccountCircleIcon />}
        onClick={handleClick}
        sx={{
          textTransform: 'none',
        }}
      >
        {`${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}`}
      </Button>
            </Grid>
            <Grid item xs={12}>
                  {/* Conditional Crust Auth Indicator */}
      {crustAuth && (
        <Chip
          label="Crust Authenticated"
          color="primary"
          icon={<VerifiedUserIcon />}
          size="small"
          variant="outlined"
        />
      )}
            </Grid>

        </Grid>
    

    

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { onSwitchAccount(); handleClose(); }}>
          Switch Account
        </MenuItem>
        <MenuItem onClick={() => { onDisconnect(); handleClose(); }}>
          Log Out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAccountMenu;
