import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function SortableItem({ id, children }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
        padding: '10px',
        margin: '5px',
        transition: 'box-shadow 0.3s ease',
        border: '2px dashed #009688'
    };

    // A function to handle filtering out drag events on input elements
    const handleMouseDown = (event) => {
        // Check if the target is an input or textarea
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.tagName === 'path'|| event.target.tagName === 'BUTTON') {
            event.stopPropagation();  // Stops the event from propagating to the drag handler
        } else {
            listeners.onMouseDown(event);  // Otherwise, proceed with normal drag handling
        }
    };
    const handleTouchStart = (event) => {
        // Check if the target is an input or textarea
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.tagName === 'path' || event.target.tagName === 'BUTTON') {
            event.stopPropagation();  // Stops the event from propagating to the drag handler
        } else {
            listeners.onTouchStart(event);  // Otherwise, proceed with normal drag handling
        }
    };
    
    return (
        <div ref={setNodeRef} style={style} {...attributes} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
        {children}
    </div>
    );
}

export default SortableItem;
