import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

const CommentForm = ({ onCommentSubmit }) => {
  const [comment, setComment] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onCommentSubmit(comment);
    setComment('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        label="Write a comment..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        multiline
        rows={4}
      />
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Post Comment
      </Button>
    </Box>
  );
};
export default CommentForm;