import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Paper,
  Box,
  CircularProgress,
  Snackbar,
  FormControlLabel,
  Switch,
  TextField
} from "@mui/material";
import {
  getDocs,
  query,
  where,
  collection,
  setDoc,
  doc,
  serverTimestamp,
  getDoc
} from "firebase/firestore";
import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function AdminPostPreviewGenerator() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [processedCount, setProcessedCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [lastSyncedPreview, setLastSyncedPreview] = useState(null);
  const [dryRun, setDryRun] = useState(false);
  const [email, setEmail] = useState("");
  const [claimSuccess, setClaimSuccess] = useState(false);
  const functions = getFunctions();
  const assignAdminRole = httpsCallable(functions, 'assignAdminRole');
  useEffect(() => {
    const fetchLastPreview = async () => {
      const previewRef = doc(db, "postPreviews", "metadata");
      const docSnap = await getDoc(previewRef);
      if (docSnap.exists()) {
        setLastSyncedPreview(docSnap.data().lastSynced);
      }
    };
    fetchLastPreview();
  }, []);

  const generatePreviews = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    setProcessedCount(0);

    try {
      const postsRef = collection(db, "posts");
      const q = query(postsRef, where("published", "==", true));
      const snapshot = await getDocs(q);
      const batch = [];
      let count = 0;

      snapshot.forEach((postDoc) => {
        const post = postDoc.data();
        const teaser = extractTeaser(post);

        const previewDoc = {
          postId: postDoc.id,
          title: post.title,
          created: post.created,
          tags: post.tags || [],
          teaser: teaser,
          published: true,
          sortDate: new Date(post.created).getTime(),
          lastUpdated: serverTimestamp()
        };

        if (!dryRun) {
          batch.push(setDoc(doc(db, "postPreviews", postDoc.id), previewDoc));
        }
        count++;
      });

      if (!dryRun) {
        await setDoc(doc(db, "postPreviews", "metadata"), { lastSynced: new Date().toLocaleString() });
        await Promise.all(batch);
        setLastSyncedPreview(new Date().toLocaleString());
      }
      setProcessedCount(count);
      setLastUpdated(new Date().toLocaleString());
      setSuccess(true);
    } catch (err) {
      setError("Failed to generate previews.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignAdmin = async () => {
    if (!email) return;
  
    try {
      assignAdminRole({ email })
      .then(result => {
        console.log(result.data);
      })
      .catch(error => {
        console.error("Error:", error);
      });
      setClaimSuccess(true);
    } catch (err) {
      console.error(err);
      setError("Failed to assign admin role.");
    }
  };

  const extractTeaser = (post) => {
    if (post.html) {
      return post.html.replace(/<[^>]+>/g, "").slice(0, 300) + "...";
    } else if (post.parts?.length > 0) {
      const firstText = post.parts.find((p) => p.type === "paragraph" || !p.type);
      return firstText?.content?.slice(0, 300) + "..." ?? "";
    }
    return "";
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Admin: Generate Blog Post Previews
        </Typography>
        <Typography variant="body1" gutterBottom>
          This tool will create optimized preview documents for all published blog posts.
        </Typography>

        {lastSyncedPreview && (
          <Typography variant="body2" color="text.secondary" mb={1}>
            Last Synced: {lastSyncedPreview}
          </Typography>
        )}

        <Box mt={2}>
          <FormControlLabel
            control={<Switch checked={dryRun} onChange={() => setDryRun(!dryRun)} color="primary" />}
            label="Dry Run (simulate only)"
          />
        </Box>

        <Box mt={3}>
          <Button variant="contained" color="primary" disabled={loading} onClick={generatePreviews}>
            {loading ? <CircularProgress size={24} /> : "Generate Previews"}
          </Button>
        </Box>

        {processedCount > 0 && (
          <Box mt={2}>
            <Typography variant="body2">
              {dryRun
                ? `Dry Run: Found ${processedCount} posts ready to process.`
                : `Processed ${processedCount} posts.`}
            </Typography>
            <Typography variant="body2">Last Updated: {lastUpdated}</Typography>
          </Box>
        )}

        <Box mt={4}>
          <Typography variant="h6">Admin Tools</Typography>
          <TextField
            label="User Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{ mt: 1, mb: 2 }}
          />
          <Button variant="outlined" color="secondary" onClick={handleAssignAdmin}>
            Assign Admin Role
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => setSuccess(false)}
        message={dryRun ? `Dry run completed! ${processedCount} posts found.` : "Post previews generated successfully!"}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => setError(null)}
        message={error}
      />

      <Snackbar
        open={claimSuccess}
        autoHideDuration={4000}
        onClose={() => setClaimSuccess(false)}
        message="Admin role assigned!"
      />
    </Container>
  );
}