import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ResponsiveImage from '../ResponsiveImage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EditCarousel = ({ images, setImages }) => {
    const [uploading, setUploading] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true // Ensures the slider adjusts height based on the content
    };

    const uploadImage = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const storage = getStorage();
        const storageRef = ref(storage, `carousel/${file.name}`);
        setUploading(true);

        try {
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            setImages([...images, url]);
        } catch (error) {
            console.error('Upload failed:', error);
        }
        setUploading(false);
    };

    const handleRemoveImage = (url) => {
        setImages(images.filter(img => img !== url));
    };

    return (
          <Box sx={{padding:2}}>
            <Typography>Carousel</Typography>
          
            <Slider {...settings}>
                {images.map((url, index) => (
                    <Box key={index} sx={{ position: 'relative', width: '100%' }}>
                         <ResponsiveImage src={url} alt={`carousel-img-${index}`}  />
                        <Button onClick={() => handleRemoveImage(url)} sx={{ position: 'absolute', top: 5, right: 5, color: 'error.main' }}>X
                        </Button>
                    </Box>
                ))}
            </Slider>
            <Box component='div' sx={{mt:5}}>
            {uploading ? <Typography>Uploading...</Typography> : (
                <Button variant="contained" component="label" startIcon={<PhotoCamera />}>
                    Upload Image
                    <input type="file" hidden onChange={uploadImage} />
                </Button>
            )}</Box>
            </Box>
        
    );
};

export default EditCarousel;
