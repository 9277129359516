import React, { useState } from 'react';
import { Box, Button, Switch, FormControlLabel, TextField, Grid } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
function ImageUpload({ imageUrl, onImageUpload, imageSide, onSideChange,imageType,maxHeight,caption, onCaptionChange }) {
  const [uploading, setUploading] = useState(false);
  const uploadImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);
    setUploading(true);

    try {
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      onImageUpload(url);
    } catch (error) {
      console.error('Upload failed:', error);
    }
    setUploading(false);
}
  const handleToggle = (event) => {
    onSideChange(event.target.checked ? 'right' : 'left');
  };


  const handleSetCaption = (event) => {
    onCaptionChange(event.target.value);
  };
  return (
    <Box sx={{
      width: '100%',
      p: 1
    }}>
      <Grid container spacing={2} alignItems="center">
       
       {imageUrl && (
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: 'auto', objectFit: 'contain' }} />
        </Grid>
       )}
          <Grid item xs={12} >
            <Button variant="contained" component="label" >
              <PhotoCamera /> 
              <input type="file" hidden onChange={uploadImage} />
            </Button>
          </Grid>
       
         <Grid item xs={12} >
              <TextField
                label="Caption"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                value={caption}
                onChange={handleSetCaption}
              />
         </Grid>
        
        {(imageType==='textImage' ) &&
         <Grid item xs={12} >
           <FormControlLabel
             control={<Switch checked={imageSide === 'right'} onChange={handleToggle} />}
             label={`Image on the ${imageSide}`}
           />
         </Grid>
        }
      </Grid>
    </Box>
  );
}

export default ImageUpload;
