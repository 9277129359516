import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, FormControlLabel, FormGroup, Tooltip, useMediaQuery } from '@mui/material';
import ImageButton from '../ImageButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import WalletConnection from '../WalletConnection';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {PeraWalletConnect} from "@perawallet/connect"
import { disconnectAndBurn, setAddress } from '../../walletSlice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {logout} from '../../firebase';
const peraWallet = new PeraWalletConnect();
const logo = process.env.REACT_APP_LOGO_URL;


export default function Nav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector(state=>state.application);
  const wallet = useSelector(state=>state.wallet);

  const [walletAddress,setWalletAddress]=useState('');
  const [role,setRole]=useState('user');
  const [signedIn,setSignedIn]=useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
const [anchor,setAnchor] = useState('left');
const [drawerOpen, setDrawerOpen] = useState(false);
useEffect(()=>{
  if(application.currentUser){
    setRole(application.currentUser.role);
    setSignedIn(true);
  }else{
setSignedIn(false);
  }
},[application.currentUser])
  useEffect(()=>{
    setWalletAddress(wallet.address);
    },[wallet.address]);

    useEffect(() => {
      console.log('walletProvider',wallet.walletProvider)
      if(wallet.walletProvider==="Pera" || wallet.walletProvider ===''){
        peraWallet
        .reconnectSession()
        .then((accounts) => {
          peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
  
          if (accounts.length) {
            console.log(`reconnecting session: ${accounts[0]}`);
            dispatch(setAddress(accounts[0]));
          }
        })
        .catch((e) => console.log(e));
      }
    
    }, []);
const handleDisconnectWalletClick = ()=>{
  dispatch(disconnectAndBurn());
}


  return (
    <>  <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{pt:1}}>
        <Toolbar>
       
          <ImageButton onClick={()=>navigate('/')} imageSrc={logo} hoverImageSrc={logo} height={isMobile?60:80} width={'auto'} />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
         <Typography fontWeight={'bold'} variant="h4" component="div" sx={{ flexGrow: 1 }}>
          
          </Typography>
         
    
         
        </Toolbar>
      </AppBar>
     
  
    </Box>
    <Drawer 
            anchor={anchor}
            open={drawerOpen}
            onClose={()=>setDrawerOpen(!drawerOpen)}
          >
    <Box
      sx={{ width: 250,height:'100%',backgroundColor:'#aec6cf'}}
      role="presentation"
      onClick={()=>setDrawerOpen(false)}
      
    >
      <Box sx={{
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center'
        }}>
      <ImageButton onClick={()=>navigate('/')} imageSrc={logo} hoverImageSrc={logo} height={'auto'} width={'100%'} />
      </Box>
      <List>
        {['About','Blog'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>navigate(text.toLowerCase())}>
              <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
         {role==="admin" && 
           <ListItem key="admin" disablePadding>
           <ListItemButton onClick={()=>navigate('/admin')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Admin</Typography>
           </ListItemButton>
         </ListItem>
        }
        {role==="admin" && 
           <ListItem key="key1" disablePadding>
           <ListItemButton onClick={()=>navigate('/manage-posts')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Manage Blog Posts</Typography>
           </ListItemButton>
         </ListItem>
        }
         {role==="admin" && 
           <ListItem key="key2" disablePadding>
           <ListItemButton onClick={()=>navigate('/mint-arc19-nft')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Mint ARC19</Typography>
           </ListItemButton>
         </ListItem>
        }
{signedIn && 
           <ListItem key="key3" disablePadding>
           <ListItemButton onClick={()=>{
                                      logout();
                                      setSignedIn(false);
                                      navigate('/');}
                                      
                                      }>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Sign Out</Typography>
           </ListItemButton>
         </ListItem>
        }
{!signedIn && 
           <ListItem key="key4" disablePadding>
           <ListItemButton onClick={()=>navigate('/sign-in')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Sign In</Typography>
           </ListItemButton>
         </ListItem>
        }
      </List>
      
    </Box>
    </Drawer>
    </>
  
  );
}