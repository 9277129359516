import React, { useState } from 'react';
import { Box, Grid, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ResponsiveImage from '../ResponsiveImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius:2
};

const ImageDisplay = ({ part }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const text = part.content ?? '';
    const side = part.imageSide ?? 'left';
    const imageUrl = part.imageUrl;
    const imageCaption = part.caption; // Assuming there's a caption property

    return (  <>
        <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ResponsiveImage src={imageUrl} alt="post-image"  handleOnClick={handleOpen} caption={imageCaption}/>
                        </Box>
                    </Grid>
        </Grid> 
         <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ResponsiveImage src={imageUrl} alt="post-image" caption={imageCaption}/>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 2 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Modal>
            </>
           
    );
};

export default ImageDisplay;
