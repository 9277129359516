import React, { useState, useEffect } from 'react';
import {Typography} from '@mui/material';
const ResponsiveImage = ({ src, alt, handleOnClick, caption}) => {
  const [maxWidth, setMaxWidth] = useState('100%');
  const [maxHeight, setMaxHeight] = useState('90%');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setMaxWidth('60%');  // Larger screens
      } else if (window.innerWidth >= 768) {
        setMaxWidth('75%');  // Medium screens
      } else {
        setMaxWidth('100%'); // Smaller screens
      }
      setMaxHeight(window.innerHeight-50)
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
   <>
     <img src={src} alt={alt} style={{
      maxWidth,
      maxHeight,
      height: 'auto',
      display: 'block',  // Makes the image a block element
      marginLeft: 'auto', // These two properties center it horizontally
      marginRight: 'auto',
      borderRadius:2,
    }} 
    onClick={handleOnClick}/>
    {caption &&
     <Typography variant="caption">{caption}</Typography>}
   </>
   
  );
};

export default ResponsiveImage;
