import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, IconButton, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ResponsiveImage from '../ResponsiveImage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ images, setImages }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true // Ensures the slider adjusts height based on the content
    };

  
    return (
       <Box sx={{padding:2}}>
            <Slider {...settings}>
                {images.map((url, index) => (
                    <Box key={index} sx={{ position: 'relative', width: '100%' }}>
                        <ResponsiveImage src={url} alt={`carousel-img-${index}`}  />
                    </Box>
                ))}
            </Slider>
            </Box>
    
    );
};

export default Carousel;
