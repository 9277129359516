import React from "react";
import { Box, TextField, Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const bulletListStyles = {
  listItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  textField: {
    flex: 1,
    marginRight: "10px",
  },
  deleteButton: {
    marginLeft: "10px",
  },
  bulletListContainer: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "8px",
  },
  bulletListDisplay: {
    padding: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    marginTop: "10px",
  },
};

const BulletListEditor = ({ value, onChange }) => {
  const handleAddItem = () => {
    onChange([...value, ""]);
  };

  const handleRemoveItem = (index) => {
    const updatedValue = value.filter((_, i) => i !== index);
    onChange(updatedValue);
  };

  const handleItemChange = (index, newValue) => {
    const updatedValue = value.map((item, i) => (i === index ? newValue : item));
    onChange(updatedValue);
  };

  return (
    <Box style={bulletListStyles.bulletListContainer}>
      <Typography variant="h6">Bullet List Editor</Typography>
      {value.map((item, idx) => (
        <Box key={idx} style={bulletListStyles.listItemContainer}>
          <TextField
            value={item}
            onChange={(e) => handleItemChange(idx, e.target.value)}
            style={bulletListStyles.textField}
            size="small"
            placeholder={`Item ${idx + 1}`}
          />
          <IconButton
            style={bulletListStyles.deleteButton}
            onClick={() => handleRemoveItem(idx)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddItem}
      >
        Add Item
      </Button>
    </Box>
  );
};

const BulletListDisplay = ({ value }) => {
  return (
    <Box style={bulletListStyles.bulletListDisplay}>
      <Typography variant="h6">Displayed List</Typography>
      <ul>
        {value.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    </Box>
  );
};

export { BulletListEditor, BulletListDisplay };
