export const MAINNET_ALGONODE_NODE = "https://mainnet-api.algonode.cloud";
export const TESTNET_ALGONODE_NODE = "https://testnet-api.algonode.cloud";

// INDEXER
export const MAINNET_ALGONODE_INDEXER = "https://mainnet-idx.algonode.cloud";
export const TESTNET_ALGONODE_INDEXER = "https://testnet-idx.algonode.cloud";

// NF
export const MAINNET_NFD_API_BASE_URL = "https://api.nf.domains";
export const TESTNET_NFD_API_BASE_URL = "https://api.testnet.nf.domains";
export const IPFS_ENDPOINT = "https://ipfs.algonode.xyz/ipfs/";

// MINT FEES
export const MINT_FEE_WALLET =
  "C5RLM45BI6G76WUHJSPPQJA6XN2LJ2MJBSHUJA26TVMSJZXAHXLO6VRTYQ";
export const MINT_FEE_PER_ASA = 0;
export const UPDATE_FEE_PER_ASA = 0;
export const ASSET_PREVIEW = "https://wallet.wen.tools/asset/";
