
import {
  Algodv2,
  algosToMicroalgos,
  computeGroupID,
  encodeAddress,
  encodeUnsignedTransaction,
  makeAssetConfigTxnWithSuggestedParamsFromObject,
  makeAssetCreateTxnWithSuggestedParamsFromObject,
  makeAssetTransferTxnWithSuggestedParamsFromObject,
  makePaymentTxnWithSuggestedParamsFromObject,
  mnemonicToSecretKey,
  signTransaction,
  makeAssetDestroyTxnWithSuggestedParamsFromObject,
  makeAssetFreezeTxnWithSuggestedParamsFromObject,
  decodeAddress, createDryrun, generateAccount,
} from "algosdk";
import {algoClient} from '../algoClient';
import { CID } from 'multiformats/cid';
import { sha256 } from 'multiformats/hashes/sha2';
import { create as createDigest } from 'multiformats/hashes/digest';
import algosdk from 'algosdk';
import { PeraWalletConnect } from "@perawallet/connect";
import axios from "axios";
import { toast } from "react-toastify";
import * as mfsha2 from "multiformats/hashes/sha2";
import * as digest from "multiformats/hashes/digest";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { DaffiWalletConnect } from "@daffiwallet/connect";
import LuteConnect from "lute-connect";
import { appId, buildAssetMintAtomicTransactionComposer, getPrice, getRandomNode, makeCrustPinTx, mnemonicSignerCreator, peraWalletSignerCreator, pinJSONToCrust } from "./crust";
import {
  DONATE_WALLET_1,
  DONATE_WALLET_2,
  MAINNET_ALGONODE_INDEXER,
  MAINNET_ALGONODE_NODE,
  MINT_FEE_PER_ASA,
  TESTNET_ALGONODE_INDEXER,
  TESTNET_ALGONODE_NODE,
  UPDATE_FEE_PER_ASA,
  CREATOR_WALLETS,
  MINT_FEE_WALLET,
  PREFIXES,
  IPFS_ENDPOINT,
  MAINNET_NFD_API_BASE_URL,
  TESTNET_NFD_API_BASE_URL,
} from "./constants";
export const peraWallet = new PeraWalletConnect({ shouldShowSignTxnToast: true });
const deflyWallet = new DeflyWalletConnect({ shouldShowSignTxnToast: true });
const daffiWallet = new DaffiWalletConnect({ shouldShowSignTxnToast: true });
const luteWallet = new LuteConnect("The Laboratory");
 async function getImageUrl(url, reserveAddr) {
  let chunks = url.split('://');
  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
    // Look for something like: template:ipfs://{ipfscid:1:raw:reserve:sha2-256} and parse into components
    chunks[0] = 'ipfs';
    const cidComponents = chunks[1].split(':');
    if (cidComponents.length !== 5) {
      // give up
      console.log('unknown ipfscid format');
      return url;
    }
    const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents;
    if (cidHash.split('}')[0] !== 'sha2-256') {
      console.log('unsupported hash:', cidHash);
      return url;
    }
    if (cidCodec !== 'raw' && cidCodec !== 'dag-pb') {
      console.log('unsupported codec:', cidCodec);
      return url;
    }
    if (asaField !== 'reserve') {
      console.log('unsupported asa field:', asaField);
      return url;
    }
    let cidCodecCode;
    if (cidCodec === 'raw') {
      cidCodecCode = 0x55;
    } else if (cidCodec === 'dag-pb') {
      cidCodecCode = 0x70;
    }

    // get 32 bytes Uint8Array reserve address - treating it as 32-byte sha2-256 hash
    const addr = algosdk.decodeAddress(reserveAddr);
    const mhdigest = createDigest(sha256.code, addr.publicKey);
    const cid = CID.create(parseInt(cidVersion), cidCodecCode, mhdigest);
    console.log('switching to id:', cid.toString());
    chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/');
    console.log('redirecting to ipfs:', chunks[1]);
    return `ipfs://${chunks[1]}`;
  }
  return url;
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
const cidToReserveURL = (cid) => {
  console.log("CID",cid);
  const decoded = CID.parse(cid)
  const { version } = decoded;
  const url = `template-ipfs://{ipfscid:${version}:dag-pb:reserve:sha2-256}`;
  const reserveAddress = algosdk.encodeAddress(
    Uint8Array.from(Buffer.from(decoded.multihash.digest))
  );
  console.log("Reserve Address",reserveAddress);
  return {
    url,
    reserveAddress,
  };
};

export async function createAssetMintArrayV2(
  data_for_txns,
  nodeURL,
  extraPinCids, // cid array
  mnemonic
) {
  const algodClient = new Algodv2("", nodeURL, {
    "User-Agent": "evil-tools",
  });

  // create atomic transaction composer
  const atc = new algosdk.AtomicTransactionComposer();
  
  const params = await algodClient.getTransactionParams().do();

  const wallet = localStorage.getItem("wallet");
  if (wallet === "" || wallet === undefined) {
    throw new Error("Wallet not found");
  }

  let txSigner = null;
  if (mnemonic !== undefined && mnemonic !== null && mnemonic !== "") {
    // create a mnemonic signer
    txSigner = mnemonicSignerCreator(mnemonic);
  } else {
    // create a new peraWalletSigner
    txSigner = peraWalletSignerCreator(peraWallet, wallet);
  }

  for (let i = 0; i < data_for_txns.length; i++) {
    try {
      const note = new TextEncoder().encode(
        JSON.stringify(data_for_txns[i].asset_note)
      );
      let asset_create_tx = makeAssetCreateTxnWithSuggestedParamsFromObject({
        from: wallet,
        manager: wallet,
        assetName: data_for_txns[i].asset_name,
        unitName: data_for_txns[i].unit_name,
        total:
          parseInt(data_for_txns[i].total_supply) *
          10 ** parseInt(data_for_txns[i].decimals),
        decimals: parseInt(data_for_txns[i].decimals),
        reserve: wallet,
        freeze: data_for_txns[i].has_freeze === "Y" ? wallet : undefined,
        assetURL: data_for_txns[i].asset_url,
        suggestedParams: params,
        note: note,
        clawback: data_for_txns[i].has_clawback === "Y" ? wallet : undefined,
        strictEmptyAddressChecking: false,
        defaultFrozen: data_for_txns[i].default_frozen === "Y" ? true : false,
      });

      let fee_tx = makePaymentTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: MINT_FEE_WALLET,
        amount: algosToMicroalgos(MINT_FEE_PER_ASA),
        suggestedParams: params,
        note: new TextEncoder().encode(
          "via wen.tools - free tools for creators and collectors | " +
          Math.random().toString(36).substring(2)
        ),
      });

      atc.addTransaction({ txn: asset_create_tx, signer: txSigner });
      atc.addTransaction({ txn: fee_tx, signer: txSigner });
    } catch (error) {
      console.log(error);
    }
  }

  // extra pinning
  if (extraPinCids) {
    for (let i = 0; i < extraPinCids.length; i++) {
      atc.addMethodCall(await makeCrustPinTx(extraPinCids[i], txSigner));
    }
  }

  return atc;
} 

 function getNodeURL() {
    return MAINNET_ALGONODE_NODE;
}
 function getIndexerURL() {
  const networkType = localStorage.getItem("networkType");
  if (networkType === "mainnet") {
    return MAINNET_ALGONODE_INDEXER;
  } else {
    return TESTNET_ALGONODE_INDEXER;
  }
}
async function signGroupTransactions(
  groups,
  wallet,
  isMultipleGroup = false
) {
  let signedTxns;
  let txnsToValidate;
  if (!isMultipleGroup) groups = [groups];
  try {
    console.info("signGroupTransactions",wallet);
    if (wallet != null) {
      await peraWallet.reconnectSession();
      const multipleTxnGroups = groups.map((group) => {
        return group.map((txn) => {
          return { txn: txn, signers: [wallet] };
        });
      });
      if (multipleTxnGroups.length === 0) {
        throw new Error("Transaction signing failed!");
      }
      signedTxns = await peraWallet.signTransaction(multipleTxnGroups);
      txnsToValidate = signedTxns.flat();
     }else{
      throw new Error("Wallet cannot be null");

     }
    if (txnsToValidate == null) {
      throw new Error("Transaction signing failed");
    }
    return txnsToValidate;
  } catch (error) { }
}
 function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
 function SignWithMnemonics(txnsArray, sk) {
  let signedTxns = [];
  for (let i = 0; i < txnsArray.length; i++) {
    signedTxns.push(signTransaction(txnsArray[i], sk).blob);
  }
  return signedTxns;
}
function createReserveAddressFromIpfsCid(ipfsCid: any) {
  const decoded = CID.parse(ipfsCid.toString());
  const version = decoded.version;
  const codec = codeToCodec(decoded.code);

  const assetURL = `template-ipfs://{ipfscid:${version}:${codec}:reserve:sha2-256}`;

  const reserveAddress = encodeAddress(
    Uint8Array.from(Buffer.from(decoded.multihash.digest))
  );

  return { assetURL, reserveAddress };
}
function codeToCodec(code: any) {
  switch (code.toString(16)) {
    case "55":
      return "raw";
    case "70":
      return "dag-pb";
    default:
      throw Error("Unknown codec");
  }
}


export async function createAssetMintArray(
  data_for_txns,
  nodeURL,
  mnemonic,
  sign = true
) {
  const algodClient = new Algodv2("", nodeURL, {
    "User-Agent": "evil-tools",
  });
  const params = await algodClient.getTransactionParams().do();
  let txnsArray = [];
  const wallet = localStorage.getItem("wallet");
  for (let i = 0; i < data_for_txns.length; i++) {
    try {
      const note = new TextEncoder().encode(
        JSON.stringify(data_for_txns[i].asset_note)
      );
      let asset_create_tx = makeAssetCreateTxnWithSuggestedParamsFromObject({
        from: wallet,
        manager: wallet,
        assetName: data_for_txns[i].asset_name,
        unitName: data_for_txns[i].unit_name,
        total:
          parseInt(data_for_txns[i].total_supply) *
          10 ** parseInt(data_for_txns[i].decimals),
        decimals: parseInt(data_for_txns[i].decimals),
        reserve: wallet,
        freeze: data_for_txns[i].has_freeze === "Y" ? wallet : undefined,
        assetURL: data_for_txns[i].asset_url,
        suggestedParams: params,
        note: note,
        clawback: data_for_txns[i].has_clawback === "Y" ? wallet : undefined,
        strictEmptyAddressChecking: false,
        defaultFrozen: data_for_txns[i].default_frozen === "Y" ? true : false,
      });

      let fee_tx = makePaymentTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: MINT_FEE_WALLET,
        amount: algosToMicroalgos(MINT_FEE_PER_ASA),
        suggestedParams: params,
        note: new TextEncoder().encode(
          "via wen.tools - free tools for creators and collectors | " +
          Math.random().toString(36).substring(2)
        ),
      });
      const groupID = computeGroupID([asset_create_tx, fee_tx]);
      asset_create_tx.group = groupID;
      fee_tx.group = groupID;
      txnsArray.push([asset_create_tx, fee_tx]);
    } catch (error) {
      console.log(error);
    }
  }
  if (sign === false) {
    return txnsArray;
  }
  if (mnemonic !== "") {
    if (mnemonic.split(" ").length !== 25) throw new Error("Invalid Mnemonic!");
    const { sk } = mnemonicToSecretKey(mnemonic);
    return SignWithMnemonics(txnsArray.flat(), sk);
  }
  const txnsToValidate = await signGroupTransactions(txnsArray, wallet, true);
  return txnsToValidate;
}

/**
 * createARC3AssetMintArrayV2 create array of transactions for minting
 * @param {*} data_for_txns 
 * @param {*} nodeURL 
 * @param {*} extraPinCids
 * @param {*} mnemonic
 * @returns AtomicTransactionComposer
 */
export async function createARC3AssetMintArrayV2(data_for_txns, nodeURL, extraPinCids, mnemonic) {
  const wallet = localStorage.getItem("wallet");
  if (wallet === "" || wallet === undefined) {
    throw new Error("Wallet not found");
  }
  const algodClient = new Algodv2("", nodeURL, {
    "User-Agent": "evil-tools",
  });

  // create atomic transaction composer
  const atc = new algosdk.AtomicTransactionComposer();

  let txSigner = null;
  if (mnemonic !== undefined && mnemonic !== null && mnemonic !== "") {
    // create a mnemonic signer
    txSigner = mnemonicSignerCreator(mnemonic);
  } else {
    // create a new peraWalletSigner
    txSigner = peraWalletSignerCreator(peraWallet, wallet);
  }

  if (txSigner === null) {
    throw new Error("txSigner is not defined");
  }

  for (let i = 0; i < data_for_txns.length; i++) {
    try {
      const jsonString = JSON.stringify(data_for_txns[i].ipfs_data);

      const authBasic = localStorage.getItem("authBasic");
      // upload to Crust
      const cid = await pinJSONToCrust(authBasic, jsonString)

      let suggestedParams = await algodClient.getTransactionParams().do();
      suggestedParams.flatFee = true;
      suggestedParams.fee = 2000 * 4; // set fee

      // build ATC
      await buildAssetMintAtomicTransactionComposer(atc, 'ARC3', txSigner, data_for_txns[i], suggestedParams, cid)

      toast.info(`Asset ${i + 1} of ${data_for_txns.length} uploaded to IPFS`, {
        autoClose: 200,
      });
    } catch (error) {
      console.error(error)
    }
  }

  // extra pinning
  if (extraPinCids) {
    for (let i = 0; i < extraPinCids.length; i++) {
      atc.addMethodCall(await makeCrustPinTx(extraPinCids[i], txSigner));
    }
  }
  
  return atc;
}

function getTxnGroupFromATC(atc) {
  const txnsWithSigners = atc.buildGroup();
  return txnsWithSigners.map((txnWithSigner) => txnWithSigner.txn);
}

export async function createARC3AssetMintArrayV2Batch(data_for_txns, nodeURL, mnemonic) {
}

export async function createARC3AssetMintArray(data_for_txns, nodeURL, token) {
}

export async function createARC19AssetMintArrayV2(data_for_txns, nodeURL, extraPinCids, mnemonic) {
  const wallet = localStorage.getItem("wallet");
  if (wallet === null || wallet === "" || wallet === undefined) {
    throw new Error("Wallet not found");
  }
  console.info("Wallet:",wallet);
  const algodClient = algoClient;

  // create atomic transaction composer
  const atc = new algosdk.AtomicTransactionComposer();

  let txSigner = null;
  if (mnemonic !== undefined && mnemonic !== null && mnemonic !== "") {
    // create a mnemonic signer
    txSigner = mnemonicSignerCreator(mnemonic);
  } else {
    // create a new peraWalletSigner
    txSigner = peraWalletSignerCreator(peraWallet, wallet);
  }

  if (txSigner === null) {
    throw new Error("txSigner is not defined");
  }

  for (let i = 0; i < data_for_txns.length; i++) {
    try {
      const jsonString = JSON.stringify(data_for_txns[i].ipfs_data);
      console.info("jsonString:",jsonString)
      const authBasic = localStorage.getItem("authBasic");
      // upload to Crust
      const cid = await pinJSONToCrust(authBasic, jsonString);

      let suggestedParams = await algodClient.getTransactionParams().do();
      suggestedParams.flatFee = true;
      suggestedParams.fee = 2000 * 4; // set fee

      // build ATC
      await buildAssetMintAtomicTransactionComposer(atc, 'ARC19', txSigner, data_for_txns[i], suggestedParams, cid);

      toast.info(`Asset ${i + 1} of ${data_for_txns.length} uploaded to IPFS`, {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error)
    }
  }

  // extra pinning
  if (extraPinCids) {
    for (let i = 0; i < extraPinCids.length; i++) {
      atc.addMethodCall(await makeCrustPinTx(extraPinCids[i], txSigner));
    }
  }

  return atc;
}


export async function createARC19AssetMintArray(data_for_txns, nodeURL, token) {
}
export function getTokenPreviewURL(assetID) {
  return "https://wallet.wen.tools/asset/" + assetID;
  // const networkType = localStorage.getItem("networkType");
  // if (networkType === "mainnet") {
  //   return "https://allo.info/asset/" + assetID;
  // }
  // return "https://testnet.explorer.perawallet.app/asset/" + assetID;
}

export function getAssetPreviewURL(assetID) {
  return "https://wallet.wen.tools/asset/" + assetID;
  // const networkType = localStorage.getItem("networkType");
  // if (networkType === "mainnet") {
  //   return "https://explorer.perawallet.app/asset/" + assetID;
  // } else {
  //   return "https://testnet.explorer.perawallet.app/asset/" + assetID;
  // }
}
export {
  base64ToArrayBuffer,
  getImageUrl,
  cidToReserveURL,
  getNodeURL,
  signGroupTransactions,
  sliceIntoChunks,
  SignWithMnemonics,
  createReserveAddressFromIpfsCid}
